<!-- This Vue component is the section on the New Patient and Patient Detail pages that shows orders data details. Only part implemented in this stage is just the table headers. -->
<template>
    <b-card class="top-row-section py-2">
        <IodReportPopup v-if="modalShow" :modalShow.sync="modalShow" :lensOrderId="lensOrderId" />
        <b-row class="mb-4">
            <b-col class="mt-2">
                <h5 class="heavy d-inline mr-1">{{ t('orders') }}</h5>
                <span class="text-gray-dark">
                    {{ t('patientDetailsViewingAllOrders') }}
                </span>
            </b-col>
        </b-row>
        <b-table
            class="no-outer-padding order-card-table"
            :fields="fields"
            :items="orderedLenses"
            sticky-header
            ref="ordersTableContainer"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>
            <template #cell(updated)="data">
                {{ data.value | date }}
            </template>
            <template #cell(orderNumber)="{value, item}">
                <custom-router-link
                    :to="{
                        name: 'OrderDetails',
                        params: {orderId: item.orderHeaderId.toString()},
                    }"
                    >{{
                        !!item.orderNumber ? item.orderNumber : item.orderHeaderId
                    }}</custom-router-link
                >
            </template>
            <template #cell(targetLensDescription)="{item, value}">
                <p class="text-nowrap text-caption heavy text-black mb-1 pl-0 ml-0">
                    <b-img :src="imageSrc(item.opEye)" class="pr-1"></b-img>
                    {{ decimalSeparatorFormatter(value.model, decimalSeparator) }}
                </p>
                <p class="text-caption light text-gray-dark mb-0">
                    {{ lensDescriptionObjToString(value, decimalSeparator) }}
                </p>
            </template>
            <template #cell(reservedLensDescription)="{item, value}">
                <LensModel :lensDescriptionDetails="value" />
                <div class="d-flex">
                    <LensPrescription :lensDescriptionDetails="value" />
                    <span
                        v-if="value.serial"
                        class="text-caption light text-gray-dark mb-0 white-space-pre"
                    >
                        #{{ value.serial }}</span
                    >
                </div>
            </template>
            <template #cell(IOD)="{item}">
                <div v-if="!item.isMto" class="cell-w-buttons justify-content-end">
                    <b-button @click="iodBtnClicked(item)" variant="outline-primary" class="mr-2">{{
                        isSidButton(item)
                    }}</b-button>
                </div>
            </template>
        </b-table>
    </b-card>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import ODIcon from '@/assets/od_icon.svg';
import OSIcon from '@/assets/os_icon.svg';
import {
    lensDescriptionObjToString,
    decimalSeparatorFormatter,
    getReservationOrderStatus,
} from '@/utilities/formatters';
import {InventorySource} from '@/constants/inventory';
import IodReportPopup from '@/views/iodreport/IodReportPopup.vue';
import LensPrescription from '@/components/LensPrescription';
import LensModel from '@/components/LensModel';

export default {
    name: 'OrdersCard',
    components: {
        IodReportPopup,
        LensPrescription,
        LensModel,
    },
    data() {
        return {
            ODIcon,
            OSIcon,
            lensOrderId: null,
            modalShow: false,
            fields: [
                {key: 'updated', label: this.t('date')},
                {key: 'orderNumber', label: this.t('ordernumber')},
                {key: 'targetLensDescription', label: this.t('targetLens')},
                {key: 'reservedLensDescription', label: this.t('orderedLens')},
                {
                    key: 'status',
                    label: this.t('statusNormalCase'),
                    formatter: (value) =>
                        this.getReservationOrderStatus(value, {
                            isConsignment: this.isConsignment(value),
                        }),
                },
                {key: 'IOD', label: this.t('actions')},
            ],
            colWidths: {
                updated: '150px',
                orderNumber: '100px',
                targetLensDescription: '200px',
                reservedLensDescription: '200px',
                status: '150px',
                ['tracking-number']: '200px',
                IOD: '100px',
            },
        };
    },

    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapState({
            orderedLenses: (state) =>
                state.patient.currentPatientLenses.filter(
                    (lens) => !(lens.isReservation && lens.status != 'Consumed')
                ),
        }),
        decimalSeparator() {
            const {decimalSeparator} = this.currentUser;
            return decimalSeparator;
        },
    },

    methods: {
        lensDescriptionObjToString,
        decimalSeparatorFormatter,
        getReservationOrderStatus,
        iodBtnClicked(item) {
            this.lensOrderId = item.lensOrderId.toString();
            this.modalShow = true;
        },
        /**
         * Determines if the lens is a Spheric or Toric for the IOD/SID button
         *
         * @param {Object} lensDetails - the lens details
         * returns button text for Spheric or Toric
         */
        isSidButton(lensDetail) {
            return lensDetail.reservedLensDescription.cylinder === undefined ? 'SID' : 'IOD';
        },

        imageSrc(opEye) {
            return this[`${opEye}Icon`];
        },

        isConsignment(lens) {
            return lens.lensSourceId == InventorySource.CONSIGNMENT;
        },
    },
};
</script>
